@use 'sass:map';
@use '@angular/material' as mat;
@include mat.elevation-classes();
@include mat.app-background();

mat.$theme-ignore-duplication-warnings: true;

$_palettes: (
  primary: (
    0: #000000,
    10: #D30050,
    20: #D30050,
    25: #D30050,
    30: #D30050,
    35: #D30050,
    40: #D30050,
    50: #D30050,
    60: #D30050,
    70: #D30050,
    80: #D30050,
    90: #D30050,
    95: #fff,
    98: #fff,
    99: #fff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #1e192b,
    20: #332d41,
    25: #3e384c,
    30: #4a4458,
    35: #564f64,
    40: #625b71,
    50: #7b748a,
    60: #958da4,
    70: #fff,
    80: #fff,
    90: #fff,
    95: #fff,
    98: #fff,
    99: #fff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #31101d,
    20: #4a2532,
    25: #56303d,
    30: #633b48,
    35: #704654,
    40: #7e5260,
    50: #996a79,
    60: #b58392,
    70: #fff,
    80: #fff,
    90: #fff,
    95: #fff,
    98: #fff,
    99: #fff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1c1b1e,
    20: #313033,
    25: #3d3b3e,
    30: #48464a,
    35: #545156,
    40: #605d62,
    50: #79767a,
    60: #938f94,
    70: #fff,
    80: #fff,
    90: #fff,
    95: #fff,
    98: #fff,
    99: #fff,
    100: #ffffff,
    4: #0f0e11,
    6: #141316,
    12: #201f22,
    17: #2b292d,
    22: #363438,
    24: #3a383c,
    87: #fff,
    92: #fff,
    94: #fff,
    96: #fff,
  ),
  neutral-variant: (
    0: #000000,
    10: #1d1a22,
    20: #322f38,
    25: #3d3a43,
    30: #49454e,
    35: #54515a,
    40: #615d66,
    50: #7a757f,
    60: #948f99,
    70: #fff,
    80: #fff,
    90: #fff,
    95: #fff,
    98: #fff,
    99: #fff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #fff,
    95: #fff,
    98: #fff,
    99: #fff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary
  ),
  density: (
    scale: 0,
  )
));

$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary
  ),
));

.mat-mdc-slide-toggle {
  --mdc-switch-selected-handle-color: #fff;
  --mdc-switch-selected-pressed-handle-color: #fff;
  --mdc-switch-selected-pressed-state-layer-color: #fff;
  --mdc-switch-selected-hover-state-layer-color: #fff;
  --mdc-switch-selected-hover-handle-color: #fff;
  --mdc-switch-selected-focus-state-layer-color: #fff;
  --mdc-switch-selected-focus-handle-color: #fff;
  --mdc-switch-selected-track-color: grey;
  --mdc-switch-selected-pressed-track-color: grey;
  --mdc-switch-selected-hover-track-color: grey;
  --mdc-switch-selected-focus-track-color: grey;
}

[data-bs-theme="light"] {
  --bs-body-bg: #fafafa;
  --bs-secondary-bg: #fafafa;
}
[data-bs-theme="dark"] {
  --bs-body-bg: #303030;
  --bs-secondary-bg: #303030;
}

.theme-dark {
  @include mat.all-component-colors($dark-theme);
}

.theme-light {
  @include mat.all-component-colors($light-theme);
}

html {
  @include mat.all-component-themes($light-theme);
}